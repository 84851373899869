// https://nuxt-community.github.io/nuxt-i18n/lazy-load-translations.html

export default {
  auth: {
    login: 'Phone',
    password: 'Password',
    loginPage: 'Login',
    loginButton: 'Login'
  },
  role: {
    cleaner_base: 'Base cleaning cleaner',
    cleaner_complex: 'Complex cleaning cleaner',
    foreman: 'Foreman'
  },
  rank: {
    junior: 'Junior cleaner',
    middle: 'Skillful cleaner',
    senior: 'Senior cleaner'

  },
  premises: {
    room1: '1-room',
    room2: '2-room',
    room3: '3-room',
    room4: '4-room',
    home: 'House',
    office: 'Office',
    per_hour: 'Hourly',
    windows: 'Windows'
  },
  types: {
    basic: 'Base',
    maintain: 'Maintain',
    office: 'Office',
    per_hour: 'Hourly',
    spring_cleaning: 'General',
    after_repair: 'After repair',
    windows: 'Windows',
    dry_cleaning: 'Dry cleaning',
    after_the_fire: 'After the fire',
    sewerage: 'Sewerage',
    remake: 'Repeated',
    wfp: 'WFP',
  },
  statuses: {
    ordered: 'Ordered',
    done: 'Done',
    payed: 'Paid',
    declined: 'Declined',
    open: 'Opened',
    close: 'Closed',
    selected_other: 'Canceled'
  },
  date: {
    years: 'year | years',
    months: 'month | months',
    days: 'day | days',
    short_years: 'years',
    short_months: 'months',
    short_days: 'days',
    day: 'Day',
    week: 'Week',
    mon: 'Mo',
    tue: 'Tu',
    wed: 'We',
    thu: 'Th',
    fri: 'Fr',
    sat: 'Sa',
    sun: 'Su',
  },
  region: {
    kyiv: 'Kyiv',
    dnepr: 'Dnipro',
    kharkov: 'Kharkiv',
    lviv: 'Lviv',
    prague: 'Prague',
    odessa: 'Odessa',
  },
  trackers: {
    order: 'Order',
    estimate: 'Estimate 💰',
    remake: 'Repeated',
    continue: 'Continue'
  },
  services: {
    clean_refrigerator: 'In the refrigerator (with groceries)',
    clean_refrigerator_full: 'In the refrigerator',
    clean_oven: 'Oven',
    range_hood: 'Kitchen hood',
    clean_microwave: 'Microwave',
    clean_cabinet: 'Cabinets',
    clean_wardrobe: 'Wardrobe',
    clean_balcony: 'Balcony',
    clean_balcony_inner: 'Loggia',
    clean_window: 'Windows',
    clean_window_full: 'Windows with cleaning',
    clean_window_side: 'Windows on one side',
    ironing: 'Ironing',
    washing_dishes: 'Washing dishes',
    per_hour: 'Hourly',
    toilet: 'Bathroom (toilet)',
    additional_toilet: 'Bathroom',
    keys_delivery: 'Delivery of keys',
    washing: 'Washing',
    washing_walls: 'Wall washing',
    clean_pet_box: 'Pet tray',
  },
  measure: {
    unit: 'units',
    kilogram: 'kg',
    liter: 'liters',
    meter: 'm',
    packaging: 'packages',
    box: 'boxes'
  },
  cleaner_portal: {
    average_rating: 'Your rating',
    logout: 'Logout',
    archive: 'Archive',
    schedule: 'Availability schedule',
    stock: 'Warehouse',
    inventory: 'Inventory',
    home: 'Home',
    profile: 'Profile',
    password: 'Password',
    change_password: 'Change password',
    manual: 'Instruction',
    future_tasks: 'Upcoming Tasks',
    archive_tasks: 'Tasks archive',
    notifications: 'Notifications',
    avatar: 'Avatar',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    phone2: 'Additional phone',
    premises_type: 'Room type',
    rank: 'Rank',
    carier_start_at: 'Start work',
    role: 'Position',
    address: 'Address',
    region: 'Region',
    card_number: 'Bank card',
    uuid: 'Identifier',
    total_resolved_tasks: 'Total completed tasks',
    features: 'Features',
    current_password: 'Current password',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    need_hoover: 'GET A HOOVER!',
    expired: '',
    order_status: 'Order status',
    order_type: 'Type of cleaning',
    additional_info: 'Additional info',
    additional_services: 'Additional services',
    colleagues: 'Colleagues',
    clean_memo: 'Technology map',
    waiting_contact_ok: 'Waiting for customer confirmation of order',
    contact_ok: 'Customer confirmed order',
    note: 'Note',
    blogger: 'Blogger',
    subscription: 'Subscription cleaning',
    company_copy: '© "<a target="_blank" href="https://www.ohayno.cz" class="hover:text-blue-500 underline">Ohayno</a>", 2018 - Present time',
    click_viewed: "I've viewed",
    click_on_place: "I'm here",
    click_done: 'Clean up done',
    button_loading: 'Updating',
    thanks: 'Thanks for your work!',
    tracker: 'Task category',
    startAt: 'Start at',
    leftTime: 'Through',
    present: 'In stock',
    salary: 'Salary',
    clarify: 'Check the salary with the manager',
    checklist: 'Cleaning checklist',
    save_checklist: 'Save cleaning checklist',
  },
  pagination: {
    from: 'from'
  },
  inputErrors: {
    empty: 'The field must not be empty'
  },
  actions: {
    save: 'Save',
    cancel: 'Cancel',
    change: 'Yes',
    ok: 'ОК',
    services: 'Add a service'
  },
  alert: {
    note: '(If several people are working on the task, the status will be updated for everyone.)',
    is_cleaner_ok: {
      title: 'Introduction',
      text: 'Have you reviewed the task?'
    },
    is_presence: {
      title: 'Ready',
      text: 'Did you arrive at the address you provided?'
    },
    is_cleaner_done: {
      title: 'Victory!',
      text: 'Task completed 🥇'
    }
  },
  success: {
    saving: 'Saving...',
    copied: 'Copied to clipboard',
    updated: 'Data updated',
    password_updated: 'Password updated'
  },
  errors: {
    notificationsBlocked: 'Notifications are blocked. Please unlock notifications to receive notifications.',
    login_error: 'Data does not match database',
    address_error: 'Address error!',
    data_not_found: 'Data not found',
    no_tasks: "No tasks found for the coming days. But don't be discouraged, managers are already working on getting you new, interesting tasks!",
    no_archive_tasks: "No tasks were found in the archive. Perhaps you should change the search criteria and everything will be found.",
    no_inventory: 'No inventory has been given to you at this time',
    no_notifications: 'No notifications found.',
    try_refresh: 'Try refreshing the page',
    request_error: 'The server is not responding. Try refreshing the page',
    ask_manager: 'You cannot change the data in the database. Please contact your manager.',
    copy_error: 'An error occurred while copying ⚠',
    need_select_service: 'Please select a service',
    right_format: 'Correct format',
    validation_error: 'Invalid data format in request. Please check your input.',
    server_error: 'An error occurred on the server processing the request. If the error persists, please contact support.'
  },
  manual: {
    howUpdate: 'How do I update the data on the page?',
    howUpdateText: "Click the 'update' button at the top of the page",
    howShowHelp: 'How can I find out what the info image means?',
    howShowHelpText: 'Clicking on most of the information pictures will show an explanatory phrase',
    howHideToast: 'How to hide a notification that has appeared?',
    howHideToastText: 'The notification disappears after 10 seconds. But if you need to remove it earlier, just swipe left or right',
    howChangeStatus: 'How do I change the status of a task?',
    howChangeStatusText: 'You can change statuses such as: "I am aware", "I am there", "Cleaning done". If you have read the task, then click "I have read". When you arrive at the place and are ready to work, then click "I am here." When the task is done, do not forget to click "Cleaning done" before leaving, please.'
  },
  filters: {
    dateRage: 'Filter by date'
  },
  schedule_statuses: {
    a: 'Available',
    v: 'Vacation',
    l: 'Going out',
    m: 'Reschedule the day',
    i: 'Illness',
    n: 'Negative',
    p: 'Disappeared',
    f: 'Kick out',
    b: 'Busy',
  },
};
